import {Deserializer, Type} from 'typeserializer';
import {Entity} from './entity';
import {DateConverter} from "../../type-converters/date.converter";
import {Structure} from "./structure";
import {Customer} from "./customer";
import {ProviderInvoiceOrderSection} from "./provider-invoice-order-section";

export class ProviderInvoiceOrder extends Entity {

  @Type(Structure)
  structure: Structure;

  @Type(Customer)
  customer: Customer;
  reference: string;
  status: ProviderInvoiceOrderStatus;
  comment: string;
  totalAmountWithoutVat: number;
  providerInvoiceOrderSection: ProviderInvoiceOrderSection[];
  isOperationPointed: boolean;
  pennylaneFileUrl: string;
  autoInvoiceEnabled: boolean;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  invoiceDate: Date | undefined;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  deadlineDate: Date | undefined;

  vatRate: string;



  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  updatedDate: Date

}
export class ProviderInvoiceOrderArray {
  @Type([ProviderInvoiceOrder])
  children: ProviderInvoiceOrder[];
}

export enum ProviderInvoiceOrderStatus {
  created = 'CREATED',
  validated = 'VALIDATED',
  invoiced = 'INVOICED',
  paid = 'PAID'

}

