
<div nz-row class="mb-16">
  <div>{{ description }}</div>
</div>


<div class="mb-16">
  <laveo-filter-provider-invoice-orders
    [originalList]="datas ? datas.data: []"
    (filtersChanged)="setFilters($event)"
    (checkedChanged)="reloadData()"
    [queryStringFilters]="queryStringFilters"
    [autofocus]="shouldAutofocusSearch"
    [checked]="checked">
  </laveo-filter-provider-invoice-orders>
</div>

<div nz-row>


  <nz-table nzShowSizeChanger
            [nzData]=" datas ? datas.data : []"
            [nzFrontPagination]="false"
            [nzLoading]="isLoading"
            [nzTotal]="datas ? datas.metadata.totalResults : 0"
            [nzPageSize]="limit"
            [nzPageIndex]="filters.page ? filters.page : 0"
            [nzNoResult]="emptyTemplate"
            (nzQueryParams)="setPage($event)"
            *ngIf="!error; else errorTemplate">
    <thead>
    <tr>
      <th nzWidth="60px" *ngIf="isAdmin">
        <label nz-checkbox [(ngModel)]="allChecked" [nzIndeterminate]="indeterminate" (ngModelChange)="onAllChecked($event)"></label>

      </th>
      <th nzColumnKey="updatedDate" [nzSortFn]="true">Date de modification</th>
      <th nzColumnKey="reference" [nzSortFn]="true" >Référence</th>
      <th nzColumnKey="customer" [nzSortFn]="true" >Client</th>
      <th nzColumnKey="structure" [nzSortFn]="true" *ngIf="isAdmin">Structure</th>
      <th nzColumnKey="totalAmountWithoutVat" [nzSortFn]="true">Total HT</th>
      <th nzColumnKey="status" [nzSortFn]="true" >Statut</th>
      <th nzColumnKey="actions" class="actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let providerInvoiceOrder of datas?.data">
      <tr>
        <td [nzChecked]="isChecked(providerInvoiceOrder)" (nzCheckedChange)="setChecked($event, providerInvoiceOrder)"
            *ngIf="userCanDelete"></td>
        <td>{{ providerInvoiceOrder.updatedDate  | dfnsFormat:('dd/MM/yy HH\'h\'mm') }}</td>
        <td>{{ providerInvoiceOrder.reference }}</td>

        <td >
          <div *ngIf="providerInvoiceOrder.customer">
            <button nz-button
                    (click)="goToCustomer(providerInvoiceOrder.customer)">{{ providerInvoiceOrder.customer.name }}<i
              nz-icon nzType="right-square" nzTheme="outline"></i></button>
          </div>
        </td>

        <td *ngIf="isAdmin">
          <div *ngIf="providerInvoiceOrder.structure">
            <button nz-button
                    (click)="goToStructure(providerInvoiceOrder.structure)">{{ providerInvoiceOrder.structure?.name }}<i
              nz-icon nzType="right-square" nzTheme="outline"></i></button>
          </div>
        </td>
        <td>{{ providerInvoiceOrder.totalAmountWithoutVat | currency: 'EUR' }}</td>
        <td>

          <div nz-col class="statut">
            <nz-tag
              nzColor="{{ providerInvoiceOrder.status | providerInvoiceOrderStatusColor }}"
              class="w-100-p"
              nz-tooltip
              [nzTooltipTitle]="providerInvoiceOrder.status | providerInvoiceOrderStatusName">
              {{ providerInvoiceOrder.status | providerInvoiceOrderStatusName}}
            </nz-tag>
          </div>

        </td>
        <td class="actions">
          <button nz-button class="ml-8" [routerLink]="['/', 'order', providerInvoiceOrder.id]">
            <i nz-icon [nz-tooltip]="isEditable(providerInvoiceOrder)?'Modifier':'Voir'" [nzType]="isEditable(providerInvoiceOrder)?'edit':'eye'"></i>
          </button>
          <button nz-button  [nz-tooltip]="providerInvoiceOrder.autoInvoiceEnabled ? 'Supprimer l\'auto-facture' : 'Supprimer le bon de commande'" class="ml-8" nzType="primary" (click)="delete(providerInvoiceOrder)" *ngIf="isDeletable(providerInvoiceOrder)">
            <i nz-icon nzType="delete"></i>
          </button>

          <button nz-button nz-tooltip="Voir le bon de commande CSV" class="ml-8" (click)="openOrderCsv(providerInvoiceOrder)" *ngIf="providerInvoiceOrder.status === ProviderInvoiceOrderStatus.validated && !providerInvoiceOrder.autoInvoiceEnabled" >
            <i nz-icon nzType="file-excel"></i>
          </button>
          <button nz-button nz-tooltip="Voir le bon de commande PDF" class="ml-8" (click)="openOrderPdf(providerInvoiceOrder)" *ngIf="providerInvoiceOrder.status !== ProviderInvoiceOrderStatus.created && !providerInvoiceOrder.autoInvoiceEnabled" >
            <i nz-icon nzType="file-text"></i>
          </button>
          <button nz-button nz-tooltip="Ajouter une facture PDF" class="ml-8" *ngIf="!providerInvoiceOrder.pennylaneFileUrl && providerInvoiceOrder.status === ProviderInvoiceOrderStatus.validated" nzType="default" (click)="showUploadModal(providerInvoiceOrder, uploadInvoiceModal)">
            <i nz-icon nzType="upload"></i>
          </button>
          <button nz-button nz-tooltip="Voir la facture PDF" class="ml-8" (click)="openPennylaneInvoicePdf(providerInvoiceOrder)" *ngIf="providerInvoiceOrder.pennylaneFileUrl" >
            <i nz-icon nzType="file-pdf"></i>
          </button>
          <button nz-button class="ml-8"  [nz-tooltip]="providerInvoiceOrder.autoInvoiceEnabled ? 'Créer l\'auto-facture': 'Valider le bon de commande'" (click)="validate(providerInvoiceOrder)" *ngIf="isAdmin && providerInvoiceOrder.status === ProviderInvoiceOrderStatus.created">
            <i nz-icon nzType="check"></i>
          </button>
          <button nz-button class="ml-8"  nz-tooltip="Marquer comme payé" (click)="markAsPaid(providerInvoiceOrder)" *ngIf="isAdmin && providerInvoiceOrder.status === ProviderInvoiceOrderStatus.invoiced">
            <i nz-icon nzType="check"></i>
          </button>

        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>

  <ng-template #uploadInvoiceModal let-modal>
    <form nz-form [formGroup]="uploadFormGroup">

      <div nz-row class="mb-16" *ngIf="! selectedOrder?.structure?.address">
        <nz-alert nzType="error"  nzMessage="L'adresse de la structure n'est pas définie. Veuillez en renseigner une avant de continuer." nzShowIcon></nz-alert>
      </div>

      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzFor="invoiceDate">Date de facturation</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-date-picker formControlName="invoiceDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzFor="deadlineDate">Limite de paiement</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-date-picker formControlName="deadlineDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzFor="vatRate">Taux de TVA</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <nz-select formControlName="vatRate">
            <nz-option *ngFor="let rate of vatRates" [nzValue]="rate.value" [nzLabel]="rate.label"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzFor="file">Fichier PDF</nz-form-label>
        <nz-form-control [nzSpan]="14">
          <input type="file" (change)="onFileSelected($event)" />
        </nz-form-control>
      </nz-form-item>
    </form>
    <div nz-row nzJustify="end">
      <button nz-button nzType="default" (click)="closeForm()">Annuler</button>
      <button nz-button nzType="primary" (click)="submitForm()"  [disabled]="! selectedOrder?.structure?.address">Valider</button>
    </div>
  </ng-template>
  <ng-template #errorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
               nzSubTitle="Une erreur est survenue lors du chargement de la liste des bons de commande">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #emptyTemplate>
    <nz-empty
      nzNotFoundImage="simple"
      nzNotFoundContent="Aucun bon de commande trouvée"
      [nzNotFoundFooter]="emptyFooter">
      <ng-template #emptyFooter>

        <div></div>
      </ng-template>
    </nz-empty>
  </ng-template>
</div>
