import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";

import {UserRight, UserRightCategory} from "../../../../../shared/models/entities/user-right";
import {PagedResponse} from "../../../../../shared/models/api/shared/paged/paged-response.interface";
import {UserRole, UserRoleType} from "../../../../../shared/models/entities/user-role";
import {BehaviorSubject, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";
import {PagedRequest} from "../../../../../shared/models/api/shared/paged/paged-request";
import {NzTableQueryParams, NzTableSortOrder} from "ng-zorro-antd/table";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiErrorMessageUtil} from "../../../../../shared/utils/api-error-message.util";
import {NzMessageService} from "ng-zorro-antd/message";
import {addDays, addMonths, isSameDay, startOfToday} from "date-fns";
import {
  ProviderInvoiceOrder,
  ProviderInvoiceOrderStatus
} from "../../../../../shared/models/entities/provider-invoice-order";
import {
  ProviderInvoiceOrdersFiltersRequest
} from "../../../../../shared/models/api/services/provider-invoice-orders-filters-requests.interface";
import {ProviderInvoiceOrdersService} from "../../../../../shared/services/api/provider-invoice-orders.service";
import {Structure} from "../../../../../shared/models/entities/structure";
import {ApolloError} from "@apollo/client/core";
import {environment} from "../../../../../../environments/environment";
import {Customer} from "../../../../../shared/models/entities/customer";

@Component({
  selector: 'laveo-provider-invoice-order-list',
  templateUrl: './provider-invoice-order-list.component.html',
  styleUrl: './provider-invoice-order-list.component.scss'
})
export class ProviderInvoiceOrderListComponent implements OnInit, OnDestroy {
  category = UserRightCategory.providerInvoiceOrders;
  isLoading = true;
  actionLoading = false;
  datas?: PagedResponse<ProviderInvoiceOrder>;
  currentPage = 1;
  limit = 10;

  searchForm: UntypedFormGroup;
  error?: Error;
  checked: ProviderInvoiceOrder[] = [];
  allChecked = false;
  indeterminate = false;
  filters: PagedRequest = new PagedRequest<ProviderInvoiceOrdersFiltersRequest>({
    page: 0,
    limit: 10,
  });
  queryStringFilters = new BehaviorSubject<PagedRequest<ProviderInvoiceOrdersFiltersRequest> | null>(null);
  tab = 0;
  userCanReadClients = false;
  userCanReadCustomerSites = false;
  userCanReadStructures = false;
  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;

  isAdmin = false;
  isStructure = false;
  showCustomerSite = false;
  currentRole?: UserRole;
  shouldAutofocusSearch = false;
  fileUploadOptions: any = {};
  uploadFormGroup!: FormGroup;
  vatRates = [
    {value: 'FR_200', label: '20%'},
    {value: 'exempt', label: '0%'}
  ];
  selectedFile: File | null = null;
  protected selectedOrder: ProviderInvoiceOrder;
  protected readonly ProviderInvoiceOrderStatus = ProviderInvoiceOrderStatus;
  private sort: { key: string; value: NzTableSortOrder }[] = [{key: 'updatedDate', value: 'descend'}];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly providerInvoiceOrderService: ProviderInvoiceOrdersService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly message: NzMessageService,
    private modal: NzModalService,
    private fb: FormBuilder
  ) {
    this.uploadFormGroup = this.fb.group({
      invoiceDate: [startOfToday(), [Validators.required]],
      deadlineDate: [addDays(startOfToday(), 30), [Validators.required]],
      vatRate: ["FR_200", [Validators.required]]
    });
  }

  get description(): string {
    const desc = 'Gérez ici les bons de commande. ';
    const total = this.datas?.metadata?.totalResults ?? 0;

    if (total > 1) {
      return desc + 'Il y a ' + total + ' bons de commande.';
    }

    if (total > 0) {
      return desc + 'Il y a ' + total + ' bon de commande.';
    }

    return desc + 'Aucun bon de commande trouvé.';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    const page = this.route.snapshot.queryParamMap.get('page');
    if (page) {
      this.filters.page = Number.parseInt(page, 10);
    }
    this.setSearchIfExist();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined = "updatedDate";
    let sortType: 'ASC' | 'DESC' | undefined = "DESC";

    const currentSort = this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }


    const parameters = new PagedRequest<ProviderInvoiceOrdersFiltersRequest>({
      page: this.filters.page,
      limit: this.limit,
      filters: this.filters?.filters,
      sortProperty,
      sortType,
      search: this.filters?.search
    });

    this.isLoading = true;
    this.error = undefined;

    const invoicesSubscription = this.providerInvoiceOrderService.allProviderInvoiceOrders(parameters).subscribe({
      next: data => {
        this.datas = data.data;
        this.updateIndeterminateState();

        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(invoicesSubscription);
  }


  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  goToStructure(fromStructure: Structure): void {
    void this.router.navigate(['/', 'admin', 'structures', fromStructure.id]);
  }

  delete(order: ProviderInvoiceOrder): void {
    this.modal.confirm({
      nzTitle: 'Suppression du bon de commande <b>' + order.reference + '</b>',
      nzContent: 'Êtes-vous sûr de vouloir supprimer le bon de commande <b>' + order.reference + '</b>?<br /><br />' +
        'Les prestations associées seront marquées comme non prises en compte , vous devrez rééditer les bons de commande des prestations associées à celle-ci.<br /><br />' +
        'Cette opération est irréversible, êtes-vous sûr(e) de vouloir continuer ?',
      nzOkText: 'Supprimer',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {

        this.actionLoading = true;
        const messageReference = this.message.loading('Suppression en cours...');

        this.providerInvoiceOrderService.delete(order.id).subscribe({
          next: () => {

            this.message.remove(messageReference.messageId);
            this.actionLoading = false;
            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.loadData();
            }, 200);
          },
          error: error => {
            this.message.remove(messageReference.messageId);
            this.actionLoading = false;
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });

      }
    });
  }

  isEditable(invoiceRequest: ProviderInvoiceOrder) {
    if (this.isAdmin && invoiceRequest.status == "CREATED") {
      return true;
    }
    return false;
  }

  isDeletable(invoiceRequest: ProviderInvoiceOrder) {
    return this.userCanDelete && (invoiceRequest.status === ProviderInvoiceOrderStatus.created);

  }

  setFilters(filters: PagedRequest<ProviderInvoiceOrdersFiltersRequest>): void {
    const queryParameters = {
      s: filters.search ?? null,
      status: (filters.filters?.status?.length ?? 0) > 0 ? filters.filters?.status?.join(',') : null,
      structures: (filters.filters?.structures?.length ?? 0) > 0 ? filters.filters?.structures?.join(',') : null,
      minDate: filters.filters?.minDate ? +filters.filters.minDate : null,
      maxDate: filters.filters?.maxDate ? +filters.filters.maxDate : null,
      page: filters.page,
      tab: this.tab,
    };

    if (filters.filters) {
      filters.filters.minDate = filters.filters.minDate ?? startOfToday();
      filters.filters.maxDate = filters.filters.maxDate ?? addMonths(startOfToday(), 6);
    }

    // Reset page to one if filters changed
    if (
      this.filters !== undefined &&
      (
        this.filters?.search !== filters.search ||
        (this.filters?.filters?.status ?? []).length !== (filters.filters?.status ?? []).length ||
        (this.filters?.filters?.structures ?? []).length !== (filters.filters?.structures ?? []).length ||
        (this.filters.filters?.minDate && filters.filters?.minDate ? !isSameDay(this.filters?.filters?.minDate, filters.filters?.minDate) : false) ||
        (this.filters.filters?.maxDate && filters.filters?.maxDate ? !isSameDay(this.filters?.filters?.maxDate, filters.filters?.maxDate) : false)
      )
    ) {
      queryParameters.page = 1;
      filters.page = 1;
    }

    this.filters = filters;

    for (const [key, value] of Object.entries(queryParameters)) {
      if (value === null) {
        delete queryParameters[key];
      }
    }

    void this.router.navigate([], { queryParams: queryParameters });

    this.loadData();
  }


  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    const page = event.pageIndex;
    this.filters.page = this.currentPage;
    void this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' });
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
    this.updateIndeterminateState();
    this.scrollUp();
  }


  reloadData(): void {
    this.loadData();
  }

  setChecked(check: boolean, invoiceRequest?: ProviderInvoiceOrder | null): void {
    if (!invoiceRequest) {
      return;
    }
    const index = this.checked.map(s => s.id).indexOf(invoiceRequest.id);
    if (check && index === -1) {
      this.checked.push(invoiceRequest);
    } else if (!check && index > -1) {
      this.checked.splice(index, 1);
    }
    this.updateIndeterminateState();
  }

  onAllChecked(checked: boolean): void {
    if (this.indeterminate) {
      // If the checkbox is in an indeterminate state, add the current page items to the selection
      this.datas?.data.forEach(item => {
        if (!this.checked.some(checkedItem => checkedItem.id === item.id)) {
          this.checked.push(item);
        }
      });
    } else {
      // If the checkbox is not in an indeterminate state, toggle the selection
      this.allChecked = checked;
      if (checked) {
        const currentPageItems = this.datas?.data.filter(item => !this.checked.some(checkedItem => checkedItem.id === item.id)) ?? [];
        this.checked = [...new Set([...this.checked, ...currentPageItems])];
      } else {
        const currentPageItemIds = this.datas?.data.map(item => item.id) ?? [];
        this.checked = this.checked.filter(item => !currentPageItemIds.includes(item.id));
      }
    }
    this.updateIndeterminateState();
  }

  updateIndeterminateState(): void {
    const currentPageItems = this.datas?.data ?? [];
    const selectedCount = currentPageItems.filter(item => this.checked.some(checkedItem => checkedItem.id === item.id)).length;
    this.indeterminate = selectedCount > 0 && selectedCount < currentPageItems.length;
    this.allChecked = selectedCount === currentPageItems.length;
  }

  isChecked(invoiceRequest: ProviderInvoiceOrder): boolean {
    return this.checked.some(checkedItem => checkedItem.id === invoiceRequest.id)
  }

  showUploadModal(order: ProviderInvoiceOrder, template: TemplateRef<any>): void {
    this.selectedOrder = order;
    this.uploadFormGroup.get('vatRate')?.setValue(this.getDefaultVatRate());
    this.modal.create({
      nzTitle: 'Ajouter une facture',
      nzContent: template,
      nzFooter: null
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  submitForm(): void {
    if (this.uploadFormGroup.valid && this.selectedFile) {
      const invoiceDate = this.uploadFormGroup.get('invoiceDate')?.value;
      const deadlineDate = this.uploadFormGroup.get('deadlineDate')?.value;
      const vatRate = this.uploadFormGroup.get('vatRate')?.value;

      this.uploadInvoice(this.selectedOrder, this.selectedFile, invoiceDate, deadlineDate, vatRate);
      this.modal.closeAll();
    } else {
      Object.values(this.uploadFormGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      });
    }
  }

  uploadInvoice(order: ProviderInvoiceOrder, file: File, invoiceDate: Date, deadlineDate: Date, vatRate: string): void {

    this.actionLoading = true;
    const messageReference = this.message.loading('Traitement en cours...');
    this.providerInvoiceOrderService.uploadInvoice(order.id, file, invoiceDate, deadlineDate, vatRate).subscribe({
      next: result => {
        this.message.remove(messageReference.messageId);
        this.actionLoading = false;
        if (result.data) {
          this.updateItemInList(result.data);

        }
      },
      error: (error_: ApolloError) => {
        this.message.remove(messageReference.messageId);
        this.actionLoading = false;
        console.error(error_);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error_));

      }
    });
  }

  updateItemInList(updatedOrder: ProviderInvoiceOrder): void {
    if (this.datas?.data) {
      const index = this.datas.data.findIndex(order => order.id === updatedOrder.id);
      if (index !== -1) {
        this.datas.data[index] = updatedOrder;
      }
    }
  }

  closeForm() {
    this.modal.closeAll();
  }

  openPennylaneInvoicePdf(providerInvoiceOrder: ProviderInvoiceOrder) {
    if (providerInvoiceOrder.pennylaneFileUrl) {
      window.open(providerInvoiceOrder.pennylaneFileUrl, '_blank');
    }
  }

  openOrderCsv(providerInvoiceOrder: ProviderInvoiceOrder) {
    window.open(environment.api.url + '/api/provider/' + providerInvoiceOrder?.id + "/download-csv", '_blank');
  }

  openOrderPdf(providerInvoiceOrder: ProviderInvoiceOrder) {
    window.open(environment.api.url + '/api/provider/' + providerInvoiceOrder?.id + "/download-pdf", '_blank');
  }

  getDefaultVatRate(): string {
    if (!this.selectedOrder.structure?.hasVat) {
      return 'exempt';
    }
    return 'FR_200';
  }

  goToCustomer(fromCustomer: Customer): void {
    void this.router.navigate(['/', 'admin', 'customers', fromCustomer.id]);
  }

  // Checked actions
  protected validate(order: ProviderInvoiceOrder): void {

    let nzContent = `Êtes-vous sûr de vouloir valider le bon de commande ${order.reference}</b> ?`;
    let nzTitle = "Validation du bon de commande";
    let nzOkText = 'Valider';

    nzContent += `<br />Attention : une fois le bon de commande validé, il ne pourra plus être modifié ni annulé.<br /><br/>
        Êtes-vous vraiment sûr(e) de vouloir continuer ? `;

    if (order.autoInvoiceEnabled) {
      nzOkText = "Créer";
      nzTitle = "Création de l'auto-facture";
      nzContent = `Êtes-vous sûr de créer l'auto-facture pour le bon de commande ${order.reference}</b> ?`;

      nzContent += `<br />Attention : une fois l'auto-facture créée, elle va être éditée et ne pourra être ni modifiée ni annulée. <br />
        Une auto-facture est une facture fournisseur éditée par Lavéo. Vous vous engagez à ce que le contenu soit exact conformément à la convention
        d'auto-facturation signée avec la structure.<br/><br />
        Êtes-vous vraiment sûr(e) de vouloir continuer ? `;
    }

    this.modal.confirm({
      nzTitle: nzTitle,
      nzContent,
      nzOkText: nzOkText,
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Exécution en cours...');
        this.providerInvoiceOrderService.validateInvoice(order.id).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            this.actionLoading = false;

            if (response.data) {

              this.updateItemInList(response.data);
            }

          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  // Checked actions
  protected markAsPaid(order: ProviderInvoiceOrder): void {

    this.actionLoading = true;
    const messageReference = this.message.loading('Validation en cours...');
    this.providerInvoiceOrderService.markAsPaid(order.id).subscribe({
      next: response => {
        this.message.remove(messageReference.messageId);
        this.actionLoading = false;

        if (response.data) {

          this.updateItemInList(response.data);
        }

      },
      error: (error: ApolloError) => {
        this.message.remove(messageReference.messageId);
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      const focus = parameters.get('f');
      const status = parameters.get('status');
      const structures = parameters.get('structures');
      const minDate = parameters.get('minDate');
      const maxDate = parameters.get('maxDate');
      const page = parameters.get('page');

      this.tab = parameters.get('tab') ? Number(parameters.get('tab')) : this.tab; // The current tab

      if (focus) {
        this.shouldAutofocusSearch = true;
        void this.router.navigate([], {queryParams: {f: null, tab:this.tab}, queryParamsHandling: 'merge'});
      }

      const newFilters = new PagedRequest<ProviderInvoiceOrdersFiltersRequest>({
        page: page ? +page : 1,
        limit: 10,
        search: search ?? undefined,
        filters: {
          status: status ? (status.split(',') as ProviderInvoiceOrderStatus[]) : [],
          structures: structures ? (structures.split(',')) : [],
          minDate: minDate ? new Date(+minDate) : undefined,
          maxDate: maxDate ? new Date(+maxDate) : undefined,
        },
        sortProperty: 'date',
        sortType: 'ASC'
      });
      this.queryStringFilters.next(newFilters);
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.isStructure = role.type === (UserRoleType.structure || UserRoleType.structureRead);
      this.userCanReadStructures = role.rights.structures.includes(UserRight.read);
      this.userCanAdd = role.rights.providerInvoiceOrders.includes(UserRight.create);
      this.userCanEdit = role.rights.providerInvoiceOrders.includes(UserRight.update);
      this.userCanDelete = role.rights.providerInvoiceOrders.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des bons de commande');
  }

  private scrollUp(): void {
    const list = document.querySelector('nz-list');
    if (list) {
      list.scrollTop = 0;
    }
  }
}
