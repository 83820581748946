import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql, MutationResult} from 'apollo-angular';
import {map} from "rxjs/operators";
import {TypeSerializerUtils} from "../../utils/type-serializer.util";
import {ApolloQueryResult} from "@apollo/client/core";
import {PagedRequest} from "../../models/api/shared/paged/paged-request";
import {PagedResponse} from "../../models/api/shared/paged/paged-response.interface";
import {InvoiceRequest, InvoiceRequestArray} from "../../models/entities/invoice-request";
import {InvoiceRequestRequest} from "../../models/api/services/invoice-request-request.interface";
import {Service} from "../../models/entities/service";
import {InvoiceRequestsFiltersRequest} from "../../models/api/services/invoice-requests-filters-requests.interface";
import moment from "moment-timezone";
import {BillingStatistics} from "../../models/entities/billing-statistics";

@Injectable({
  providedIn: 'root'
})
export class InvoiceRequestsService {
  constructor(
    private readonly apollo: Apollo
  ) {
  }


  delete(id: string): Observable<MutationResult<boolean>> {
    const mutation = gql`
      mutation deleteInvoiceRequest($id: Guid!) {
        invoiceRequests {
          delete(id: $id)
        }
      }
    `;

    const variables = {
      id
    };

    return this.apollo.mutate<boolean>({
      mutation,
      variables
    });
  }

  invoiceRequest(id: string): Observable<ApolloQueryResult<InvoiceRequest>> {
    const query = gql`
      query invoiceRequest($id: Guid!){
        invoiceRequests  {
          invoiceRequest(id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.query<{ invoiceRequests: { invoiceRequest: InvoiceRequest } }>({
      query,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests?.invoiceRequest, InvoiceRequest),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }


  allInvoices(parameters: PagedRequest<InvoiceRequestsFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<InvoiceRequest>>> {
    const query = gql`
      query invoiceRequests($offset: Int, $limit: Int, $filters: InvoiceRequestsFiltersRequest,  $sortProperty: String, $sortType: ESortType, $search: String) {
        invoiceRequests {
          invoiceRequests(offset: $offset, limit: $limit, filters: $filters,  sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              customerSite {
                name
                id

              }
              customer {
                name
                id
              }
              updatedDate
              createdDate
              totalAmountWithoutVat
              totalAmount
              vatAmount
              reference
              orderReference
              companyName
              address
              postalCode
              city
              country
              status
              statusReason
              pennylaneFileUrl
              pennylaneInvoiceNumber
              pennylanePublicUrl
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ invoiceRequests: { invoiceRequests: PagedResponse<InvoiceRequest> } }>({
      query, variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        filters: parameters.filters,
        search: parameters.search
      },
      fetchPolicy: 'network-only'
    }).pipe(map(result => {
      const raw = result.data?.invoiceRequests?.invoiceRequests;
      const response: PagedResponse<InvoiceRequest> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, InvoiceRequestArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  updateInvoice(data: InvoiceRequestRequest): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation updateInvoice($id: Guid!, $data: InvoiceRequestRequest) {
        invoiceRequests {

          update(id: $id, data: $data) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;

    return this.apollo.mutate<{ invoiceRequests: { update: InvoiceRequest } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          reference: data.reference,
          orderReference: data.orderReference
        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests?.update, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  updateOrderReference(id: string, orderReference: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation updateOrderReference($id: Guid!, $orderReference: String!) {
        invoiceRequests {
          updateOrderReference(id: $id, orderReference: $orderReference) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;

    return this.apollo.mutate<{ invoiceRequests: { updateOrderReference: InvoiceRequest } }>({
      mutation,
      variables: {
        id: id,
        orderReference: orderReference
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests?.updateOrderReference, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  deleteInvoiceDetail(id: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        invoiceRequests {
          removeDetail (id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.mutate<{ invoiceRequests: { removeDetail: InvoiceRequest } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests.removeDetail, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  validateInvoice(id: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        invoiceRequests {
          validate (id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.mutate<{ invoiceRequests: { validate: InvoiceRequest } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests.validate, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  validateInvoiceMultiple(...ids: string[]): Observable<MutationResult<InvoiceRequest[]>> {
    const query = `
      mutation validateInvoices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        invoiceRequests {\n` + ids.map((_, index) => 'id' + index + ': validate (id: $id' + index + `) {
      id
      customerSite {
        name
        id

      }
      customer {
        name
        id
      }
      updatedDate
      createdDate
      totalAmountWithoutVat
      totalAmount
      invoiceRequestSection {
        id
        name
        rank

        invoiceRequestDetail {
          id
          articleReference
          articleLabel
          additionalDescription
          needValidation
          priceUnitTotal
          priceUnitWithoutVat
          priceTotalWithoutVat
          priceTotal
          serviceId
          vat
          quantity
          vatUnitAmount
          vatTotalAmount
          licensePlate
        }
      }
      vatAmount
      reference
      orderReference
      companyName
      address
      postalCode
      city
      country
      status
      statusReason
      pennylaneFileUrl
      pennylaneInvoiceNumber
      pennylanePublicUrl
    }

       `).join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response: any, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.invoiceRequests);
      data = data.filter(s => s !== 'InvoiceRequestMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  cancelMultiple(...ids: string[]): Observable<MutationResult<boolean[]>> {
    const query = `
      mutation deleteInvoiceRequest(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        invoiceRequests {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response: any, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.invoiceRequests);
      data = data.filter(s => s !== 'InvoiceRequestMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }


  markAsPaid(id: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        invoiceRequests {
          markAsPaid (id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.mutate<{ invoiceRequests: { markAsPaid: InvoiceRequest } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests.markAsPaid, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  billingStatistics(from: Date, to: Date, customer: string | null, customerSite: string| null, alsoDisplayGenerated = false): Observable<ApolloQueryResult<{
    invoiceRequests: { getBillingStatistics: BillingStatistics }
  }>> {
    const timeZone = 'Europe/Paris'; // Define your timezone
    const fromTz = moment(from).tz(timeZone).format();
    const toTz = moment(to).tz(timeZone).format();


    const query = gql`
      query billingStatistics($startDate: DateTime!, $endDate: DateTime!, $customer: Guid, $customerSite: Guid, $alsoDisplayGenerated: Boolean!) {
        invoiceRequests {
          getBillingStatistics(startDate: $startDate, endDate: $endDate, customer: $customer, customerSite: $customerSite, alsoDisplayGenerated: $alsoDisplayGenerated) {
            monthly {
              year
              month
              totalAmountWithoutVat
              providerTotalAmountWithoutVat
              margin
              marginPercentage
            }
            daily {
              year
              month
              day
              totalAmountWithoutVat
              providerTotalAmountWithoutVat
              margin
              marginPercentage
            }
          }
        }
      }
    `;

    const variables: any = {
      startDate: fromTz,
      endDate: toTz,
      alsoDisplayGenerated
    };

    if (customer && customer !== '') {
      variables.customer = customer;
    }

    if (customerSite && customerSite !== '') {
      variables.customerSite = customerSite;
    }

    return this.apollo.query<{ invoiceRequests: { getBillingStatistics: BillingStatistics } }>({
      query,
      variables
    });

  }


}
