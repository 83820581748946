import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

import {UserRight, UserRightCategory} from "../../../../../shared/models/entities/user-right";
import {PagedResponse} from "../../../../../shared/models/api/shared/paged/paged-response.interface";
import {CustomerSite} from "../../../../../shared/models/entities/customer-site";
import {UserRole, UserRoleType} from "../../../../../shared/models/entities/user-role";
import {BehaviorSubject, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";
import {PagedRequest} from "../../../../../shared/models/api/shared/paged/paged-request";
import {InvoiceRequest, StatusInvoice} from "../../../../../shared/models/entities/invoice-request";
import {InvoiceRequestsService} from "../../../../../shared/services/api/invoice-requests.service";
import {Customer} from "../../../../../shared/models/entities/customer";
import {NzTableQueryParams, NzTableSortOrder} from "ng-zorro-antd/table";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiErrorMessageUtil} from "../../../../../shared/utils/api-error-message.util";
import {NzMessageService} from "ng-zorro-antd/message";
import {addMonths, isSameDay, startOfToday} from "date-fns";
import {
  InvoiceRequestsFiltersRequest
} from "../../../../../shared/models/api/services/invoice-requests-filters-requests.interface";
import {ProviderInvoiceOrderStatus} from "../../../../../shared/models/entities/provider-invoice-order";
import {ApolloError} from "@apollo/client/core";

@Component({
  selector: 'laveo-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrl: './invoice-list.component.scss'
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  category = UserRightCategory.invoiceRequests;
  isLoading = true;
  actionLoading = false;
  datas?: PagedResponse<InvoiceRequest>;
  currentPage = 1;
  limit = 10;
  tab = 0;


  searchForm: UntypedFormGroup;
  error?: Error;
  checked: InvoiceRequest[] = [];
  allChecked = false;
  indeterminate = false;
  filters: PagedRequest = new PagedRequest<InvoiceRequestsFiltersRequest>({
    page: 0,
    limit: 10,
  });
  queryStringFilters = new BehaviorSubject<PagedRequest<InvoiceRequestsFiltersRequest> | null>(null);

  userCanReadClients = false;
  userCanReadCustomerSites = false;
  userCanReadStructures = false;
  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;

  isAdmin = false;
  isStructure = false;
  showCustomerSite = false;
  currentRole?: UserRole;
  shouldAutofocusSearch = false;
  protected readonly ProviderInvoiceOrderStatus = ProviderInvoiceOrderStatus;
  protected readonly StatusInvoice = StatusInvoice;
  private sort: { key: string; value: NzTableSortOrder }[] = [{key: 'updatedDate', value: 'descend'}];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly invoiceRequestService: InvoiceRequestsService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly message: NzMessageService,
    private modal: NzModalService,
  ) {}

  get description(): string {
    const desc = 'Gérez ici les factures. ';
    const total = this.datas?.metadata?.totalResults ?? 0;

    if (total > 1) {
      return desc + 'Il y a ' + total + ' factures.';
    }

    if (total > 0) {
      return desc + 'Il y a ' + total + ' facture.';
    }

    return desc + 'Aucune facture trouvée.';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();

    const page = this.route.snapshot.queryParamMap.get('page');
    if (page) {
      this.filters.page = Number.parseInt(page, 10);
    }
    this.setSearchIfExist();
    this.loadData();

  }



  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined = "updatedDate";
    let sortType: 'ASC' | 'DESC' | undefined = "DESC";

    const currentSort = this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }


    //const search: string = this.searchForm.get('search')?.value;

    const parameters = new PagedRequest<InvoiceRequestsFiltersRequest>({
      page: this.filters.page,
      limit: this.limit,
      filters: this.filters?.filters,
      sortProperty,
      sortType,
      search: this.filters?.search
    });

    this.isLoading = true;
    this.error = undefined;

    const invoicesSubscription = this.invoiceRequestService.allInvoices(parameters).subscribe({
      next: data => {
        this.datas = data.data;

        this.isLoading = data.loading;
        this.updateIndeterminateState();
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(invoicesSubscription);
  }



  goToCustomer(fromCustomer: Customer): void {
    void this.router.navigate(['/', 'admin', 'customers', fromCustomer.id]);
  }

  goToCustomerSite(fromCustomerSite: CustomerSite): void {
    void this.router.navigate(['/', 'admin', 'customer-sites', fromCustomerSite.id]);
  }

  delete(invoiceRequest: InvoiceRequest): void {
    this.modal.confirm({
      nzTitle: 'Suppression de la facture <b>' + invoiceRequest.reference + '</b>',
      nzContent: 'Êtes-vous sûr de vouloir supprimer la facture <b>' + invoiceRequest.reference + '</b>?<br /><br />' +
        'Les prestations associées seront marquées comme non facturées, vous devrez rééditer les factures des prestations associées à celle-ci.<br /><br />' +
        'Cette opération est irréversible, êtes-vous sûr(e) de vouloir continuer ?',
      nzOkText: 'Supprimer',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {


        this.actionLoading = true;
        const messageReference = this.message.loading('Suppression en cours...');
        this.invoiceRequestService.delete(invoiceRequest.id).subscribe({
          next: () => {

            this.message.remove(messageReference.messageId);
            this.actionLoading = false;
            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.loadData();
            }, 200);
          },
          error: error => {
            this.message.remove(messageReference.messageId);
            this.actionLoading = false;
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });

      }
    });
  }

  // Open the invoice PDF
  openPdf(invoiceRequest: InvoiceRequest) {
    if (invoiceRequest.pennylaneFileUrl) {
      window.open(invoiceRequest.pennylaneFileUrl, '_blank');
    }
  }

  isEditable(invoiceRequest: InvoiceRequest) {
    if (this.isAdmin && (invoiceRequest.status == "CREATED" || invoiceRequest.status == "VALIDATION_REQUIRED" || invoiceRequest.status == "CUSTOMER_VALIDATION_REQUIRED")) {
      return true;
    } else if ((this.userCanReadClients || this.userCanReadCustomerSites) && invoiceRequest.status == "CUSTOMER_VALIDATION_REQUIRED") {
      return true;
    }
    return false;
  }

  isDeletable(invoiceRequest: InvoiceRequest) {
    return this.userCanDelete && (invoiceRequest.status !== StatusInvoice.invoiced && invoiceRequest.status !== StatusInvoice.paid);

  }

  setFilters(filters: PagedRequest<InvoiceRequestsFiltersRequest>): void {
    const queryParameters = {
      s: filters.search ?? null,
      status: (filters.filters?.status?.length ?? 0) > 0 ? filters.filters?.status?.join(',') : null,
      customers: (filters.filters?.customers?.length ?? 0) > 0 ? filters.filters?.customers?.join(',') : null,
      customerSites: (filters.filters?.customerSites?.length ?? 0) > 0 ? filters.filters?.customerSites?.join(',') : null,
      minDate: filters.filters?.minDate ? +filters.filters.minDate : null,
      maxDate: filters.filters?.maxDate ? +filters.filters.maxDate : null,
      page: filters.page,
      tab: this.tab,
    };

    if (filters.filters) {
      filters.filters.minDate = filters.filters.minDate ?? startOfToday();
      filters.filters.maxDate = filters.filters.maxDate ?? addMonths(startOfToday(), 6);
    }

    // Reset page to one if filters changed
    if (
      this.filters !== undefined &&
      (
        this.filters?.search !== filters.search ||
        (this.filters?.filters?.status ?? []).length !== (filters.filters?.status ?? []).length ||
        (this.filters?.filters?.customers ?? []).length !== (filters.filters?.customers ?? []).length ||
        (this.filters?.filters?.customerSites ?? []).length !== (filters.filters?.customerSites ?? []).length ||
        (this.filters.filters?.minDate && filters.filters?.minDate ? !isSameDay(this.filters?.filters?.minDate, filters.filters?.minDate) : false) ||
        (this.filters.filters?.maxDate && filters.filters?.maxDate ? !isSameDay(this.filters?.filters?.maxDate, filters.filters?.maxDate) : false)
      )
    ) {
      queryParameters.page = 1;
      filters.page = 1;
    }

    this.filters = filters;

    for (const [key, value] of Object.entries(queryParameters)) {
      if (value === null) {
        delete queryParameters[key];
      }
    }

    void this.router.navigate([], { queryParams: queryParameters });

    this.loadData();
  }


  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    const page = event.pageIndex;
    this.filters.page = this.currentPage;
    void this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' });
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
    this.updateIndeterminateState();
    this.scrollUp();
  }


  addService(): void {
    void this.router.navigate(['/', 'prestations', 'new']);
  }

  reloadData(): void {
    this.loadData();
  }

  setChecked(check: boolean, invoiceRequest?: InvoiceRequest | null): void {
    if (!invoiceRequest) {
      return;
    }
    const index = this.checked.map(s => s.id).indexOf(invoiceRequest.id);
    if (check && index === -1) {
      this.checked.push(invoiceRequest);
    } else if (!check && index > -1) {
      this.checked.splice(index, 1);
    }
    this.updateIndeterminateState();
  }

  onAllChecked(checked: boolean): void {
    if (this.indeterminate) {
      // If the checkbox is in an indeterminate state, add the current page items to the selection
      this.datas?.data.forEach(item => {
        if (!this.checked.some(checkedItem => checkedItem.id === item.id)) {
          this.checked.push(item);
        }
      });
    } else {
      // If the checkbox is not in an indeterminate state, toggle the selection
      this.allChecked = checked;
      if (checked) {
        const currentPageItems = this.datas?.data.filter(item => !this.checked.some(checkedItem => checkedItem.id === item.id)) ?? [];
        this.checked = [...new Set([...this.checked, ...currentPageItems])];
      } else {
        const currentPageItemIds = this.datas?.data.map(item => item.id) ?? [];
        this.checked = this.checked.filter(item => !currentPageItemIds.includes(item.id));
      }
    }
    this.updateIndeterminateState();
  }

  updateIndeterminateState(): void {
    const currentPageItems = this.datas?.data ?? [];
    const selectedCount = currentPageItems.filter(item => this.checked.some(checkedItem => checkedItem.id === item.id)).length;
    this.indeterminate = selectedCount > 0 && selectedCount < currentPageItems.length;
    this.allChecked = selectedCount === currentPageItems.length;
  }

  isChecked(invoiceRequest: InvoiceRequest): boolean {
    return this.checked.some(checkedItem => checkedItem.id === invoiceRequest.id)
  }

  updateItemInList(updatedItem: InvoiceRequest): void {
    if (this.datas?.data) {
      const index = this.datas.data.findIndex(order => order.id === updatedItem.id);
      if (index !== -1) {
        this.datas.data[index] = updatedItem;
      }
    }
  }

  // Checked actions
  protected validate(invoiceRequest: InvoiceRequest): void {
    let nzContent = `Êtes-vous sûr de vouloir valider la facture sélectionnée ${invoiceRequest.reference}</b> ?`;

    nzContent += `<br />Attention : une fois la facture validée, elle va être envoyée à Pennylane et ne pourra plus être modifiée  ni annulée.<br /><br/>
        Êtes-vous vraiment sûr(e) de vouloir continuer ? `;

    this.modal.confirm({
      nzTitle: 'Valider la facture',
      nzContent,
      nzOkText: 'Valider',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Validation en cours...');
        this.invoiceRequestService.validateInvoice(invoiceRequest.id).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            this.actionLoading = false;

            if (response.data) {
              this.updateItemInList(response.data);
            }
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  // Checked actions
  protected markAsPaid(invoice: InvoiceRequest): void {

    this.actionLoading = true;
    const messageReference = this.message.loading('Validation en cours...');
    this.invoiceRequestService.markAsPaid(invoice.id).subscribe({
      next: response => {
        this.message.remove(messageReference.messageId);
        this.actionLoading = false;

        if (response.data) {

          this.updateItemInList(response.data);
        }

      },
      error: (error: ApolloError) => {
        this.message.remove(messageReference.messageId);
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }




  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      const focus = parameters.get('f');
      const status = parameters.get('status');
      const customers = parameters.get('customers');
      const customerSites = parameters.get('customerSites');
      const minDate = parameters.get('minDate');
      const maxDate = parameters.get('maxDate');
      const page = parameters.get('page');

      this.tab = parameters.get('tab') ? Number(parameters.get('tab')) : this.tab; // The current tab

      if (focus) {
        this.shouldAutofocusSearch = true;
        void this.router.navigate([], {queryParams: {f: null, tab:this.tab}, queryParamsHandling: 'merge'});
      }

      const newFilters = new PagedRequest<InvoiceRequestsFiltersRequest>({
        page: page ? +page : 1,
        limit: 10,
        search: search ?? undefined,
        filters: {
          status: status ? (status.split(',') as StatusInvoice[]) : [],
          customers: customers ? (customers.split(',')) : [],
          customerSites: customerSites ? (customerSites.split(',')) : [],
          minDate: minDate ? new Date(+minDate) : undefined,
          maxDate: maxDate ? new Date(+maxDate) : undefined,
        },
        sortProperty: 'date',
        sortType: 'ASC'
      });
      this.queryStringFilters.next(newFilters);
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.isStructure = role.type === (UserRoleType.structure || UserRoleType.structureRead);
      this.showCustomerSite = (role.type === UserRoleType.admin || role.type === UserRoleType.customer);
      this.userCanReadClients = role.rights.customers.includes(UserRight.read);
      this.userCanReadCustomerSites = role.rights.customerSites.includes(UserRight.read);
      this.userCanReadStructures = role.rights.structures.includes(UserRight.read);
      this.userCanAdd = role.rights.invoiceRequests.includes(UserRight.create);
      this.userCanEdit = role.rights.invoiceRequests.includes(UserRight.update);
      this.userCanDelete = role.rights.invoiceRequests.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des factures');
  }

  private scrollUp(): void {
    const list = document.querySelector('nz-list');
    if (list) {
      list.scrollTop = 0;
    }
  }
}
