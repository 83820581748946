import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NzUploadFile, NzUploadXHRArgs} from "ng-zorro-antd/upload";
import {of, Subscription, throwError} from "rxjs";
import {ApolloError} from "@apollo/client/core";
import {ApiErrorMessageUtil} from "../../../../shared/utils/api-error-message.util";
import {delay, switchMap} from "rxjs/operators";
import {UserService} from "../../../../shared/services/api/user.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {Title} from "@angular/platform-browser";
import {UserRoleType} from "../../../../shared/models/entities/user-role";
import {PricingService} from "../../../../shared/services/api/pricing.service";
import {BatchProcessFailed} from "../../../../shared/models/entities/batch-process-pricing";
import {NzModalService} from "ng-zorro-antd/modal";
import {ServicesService} from "../../../../shared/services/api/services.service";
import {ProgressNotifierComponent} from "../../shared/progress-notifier/progress-notifier.component";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {endOfMonth, endOfToday, endOfWeek, startOfMonth, startOfToday, startOfWeek} from "date-fns";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ProviderInvoiceOrdersService} from "../../../../shared/services/api/provider-invoice-orders.service";

@Component({
  selector: 'laveo-admin-pricing',
  templateUrl: './admin-pricing.component.html',
  styleUrl: './admin-pricing.component.scss',
  providers: [DatePipe]
})
export class AdminPricingComponent implements OnInit, OnDestroy {

  @ViewChild('invoiceNotifier') invoiceNotifier: ProgressNotifierComponent;
  @ViewChild('providerOrderInvoiceNotifier') providerOrderInvoiceNotifier: ProgressNotifierComponent;
  @ViewChild('importNotifier') importNotifier: ProgressNotifierComponent;
  @ViewChild('exportNotifier') exportNotifier: ProgressNotifierComponent;
  userIsAdmin = false;
  actionLoading = false;
  // Import
  importFileList: NzUploadFile[] = [];
  importErrorModalVisible = false;
  importErrors?: BatchProcessFailed[];

  excelPath =  '/excel/laveo-pricing-template.xlsx';

  // Range of dates for invoice generation
  dateRanges: Record<string, Date[]> = {};
  generateForm: UntypedFormGroup;

  //private currentRoleId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly pricingService: PricingService,
    private readonly message: NzMessageService,
    private readonly modal: NzModalService,
    private readonly servicesService: ServicesService,
    private readonly providerInvoiceOrderService: ProviderInvoiceOrdersService,
    private readonly titleService: Title,
    private readonly datePipe: DatePipe,
    private http: HttpClient
  ) {}


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setGenerateForm();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  private setGenerateForm(): void {
    this.generateForm = this.formBuilder.group({
      dates: this.formBuilder.control([startOfMonth(startOfToday()), startOfToday()]),
      datesProvider: this.formBuilder.control([startOfMonth(startOfToday()), startOfToday()]),

      includeAllClients: [false]
    });
    this.dateRanges = {
      'Aujourd\'hui': [startOfToday(), endOfToday()],
      'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      'Ce mois-ci': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };
  }



  import = (item: NzUploadXHRArgs): Subscription => item.postFile as File ? this.pricingService.importPricings(item.postFile as File).subscribe({
    next: (response) => {
      if (response.data?.processOk?.length ?? 0 > 0) {
        this.message.success(`Import de ${response.data?.processOk?.length} tarifs réussi`);
      } else {
        this.message.warning(`Aucun tarif importé. Vérifiez votre fichier`);
      }

      if (response.data?.processFailed?.length ?? 0 > 0) {
        this.importErrorModalVisible = true;
        this.importErrors = response.data?.processFailed;
      }

      if (item.onSuccess) {
        item.onSuccess(null, item.file, null);
        this.importFileList = [];
      }
    },
    error: (error: ApolloError) => {
      console.error(error);
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  }) : of('').pipe(
    delay(500),
    switchMap(() => throwError(() => new Error('Erreur lors de l\'envoi. Réessayer')))
  ).subscribe({
    error: (error) => {
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  });

  handleChange(fileList: NzUploadFile[]): void {
    if (fileList?.length > 0) {
      this.importFileList = fileList.slice(-1);
    }
  }

  closeImportErrorModal(): void {
    this.importErrorModalVisible = false;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      //this.currentRoleId = role.actor.id;
      this.userIsAdmin = role.type === UserRoleType.admin;

    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Tarifs');
  }


  launchInvoicing() {

    this.resetAllProgresses();

    // Check the dates first
    let minDate:Date|null = null;
    let maxDate:Date;

    const datesControl = this.generateForm.get('dates');
    const includeAllClients = this.generateForm.get('includeAllClients')?.value;
    if (datesControl) {
      const dates = datesControl.value;
      minDate = dates.length > 0 ? new Date(dates[0]) : null;
      maxDate = dates.length > 0 ? new Date(dates[1]) : new Date();
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(23, 59, 29, 999);

    }
    if (minDate == null) {
      this.message.error("Vous devez saisir une date de début pour la facturation.");
    }
    else {
      // Facturer
      this.modal.confirm({
        nzTitle: 'Facturer ?',
        nzContent: 'Êtes-vous sûr de vouloir générer les factures pour l\'ensemble des sites clients ?',
        nzOkText: 'Facturer',
        nzOkType: 'primary',
        nzCancelText: 'Annuler',
        nzOnOk: () => {
          this.actionLoading = true;

          this.servicesService.prepareAllInvoices(minDate, maxDate, includeAllClients).subscribe({
            next: _ => {
              this.actionLoading = false;
            },
            error: error => {
              this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
              this.actionLoading = false;
            }
          });


        }
      });
    }
  }

  protected resetAllProgresses() {
    this.invoiceNotifier.reset();
    this.exportNotifier.reset();
    this.importNotifier.reset();
    this.providerOrderInvoiceNotifier.reset();
  }

  exportExcel() {
    this.resetAllProgresses();
    this.http.post(this.excelPath, {}, { responseType: 'blob' }).subscribe((response: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(response);
      a.href = objectUrl;
      a.download = "laveo_tarifs_"+ this.datePipe.transform(new Date(), 'yyyy-MM-dd-HH\'h\'mm')+ ".xlsx" ;
      a.style.display = 'none';
      document.body.append(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(objectUrl);
    });
  }

  launchProviderInvoiceOrders() {

    this.resetAllProgresses();

    // Check the dates first
    let minDate:Date|null = null;
    let maxDate:Date;

    const datesControl = this.generateForm.get('datesProvider');
    if (datesControl) {
      const dates = datesControl.value;
      minDate = dates.length > 0 ? new Date(dates[0]) : null;
      maxDate = dates.length > 0 ? new Date(dates[1]) : new Date();
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(23, 59, 29, 999);

    }
    if (minDate == null) {
      this.message.error("Vous devez saisir une date de début pour la génération des bons de commande.");
    }
    else {
      // Facturer
      this.modal.confirm({
        nzTitle: 'Générer les bons de commande / auto-factures ?',
        nzContent: 'Êtes-vous sûr de vouloir générer les bons de commande / auto-factures pour l\'ensemble des structures ?',
        nzOkText: 'Générer',
        nzOkType: 'primary',
        nzCancelText: 'Annuler',
        nzOnOk: () => {
          this.actionLoading = true;

          this.providerInvoiceOrderService.prepareAllOrderInvoices(minDate, maxDate).subscribe({
            next: _ => {
              this.actionLoading = false;
            },
            error: error => {
              this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
              this.actionLoading = false;
            }
          });


        }
      });
    }
  }
}
