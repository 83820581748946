import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stripIgnoredCharacters } from 'graphql/utilities';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log("Request URL :", request.url);
    if (request.url.startsWith('http') || request.url.startsWith('blob')) {
      return next.handle(request);
    }

    let newRequest = environment.api.mock ? request : this.addBaseUrl(request);
    newRequest = this.addAuthHeader(newRequest);
    newRequest = this.addRoleHeader(newRequest);
    newRequest = this.shrinkQuery(newRequest);

    return next.handle(newRequest).pipe(
      catchError((error: Error) => {
        if ((error as HttpErrorResponse)?.status === 401 && this.authService.canRefresh) {
          return this.authService.refresh().pipe(switchMap(() => next.handle(this.addAuthHeader(request))));
        }

        return throwError(() => error);
      })
    );
  }

  private addBaseUrl(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.startsWith('/assets')) {
      return request;
    }

    if (request.url.startsWith('/')) {
      return request.clone({
        url: `${environment.api.url}${request.url}`
      });
    }
    return request.clone({
      url: `${environment.api.url}/v${environment.api.version}/${request.url}`
    });
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const { token } = this.authService;
    if (token && token !== '') {
      return request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    return request;
  }

  private addRoleHeader(request: HttpRequest<any>): HttpRequest<any> {
    const roleId = this.authService.roleId;
    if (roleId) {
      return request.clone({
        headers: request.headers.set('X-User-Role', roleId)
      });
    }

    return request;
  }

  private shrinkQuery(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes(environment.api.graphQL)) {
      const body = request.serializeBody();
      if (body && typeof body === 'string') {
        try {
          const jsonBody = JSON.parse(body);
          if (jsonBody?.query) {
            const strippedQuery = stripIgnoredCharacters(jsonBody.query);
            jsonBody.query = strippedQuery;
            const bodyWithNewQuery = JSON.stringify(jsonBody);
            return request.clone({
              body: bodyWithNewQuery,
              headers: request.headers.set('Content-Type', 'application/json')
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    return request;
  }
}
