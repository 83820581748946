import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql, MutationResult} from 'apollo-angular';
import {map} from "rxjs/operators";
import {TypeSerializerUtils} from "../../utils/type-serializer.util";
import {ApolloQueryResult} from "@apollo/client/core";
import {PagedRequest} from "../../models/api/shared/paged/paged-request";
import {PagedResponse} from "../../models/api/shared/paged/paged-response.interface";
import {Service} from "../../models/entities/service";
import {ProviderInvoiceOrder, ProviderInvoiceOrderArray} from "../../models/entities/provider-invoice-order";
import {ProviderInvoiceOrderRequest} from "../../models/api/services/provider-invoice-order-request.interface";
import {
  ProviderInvoiceOrdersFiltersRequest
} from "../../models/api/services/provider-invoice-orders-filters-requests.interface";
import moment from "moment-timezone";

@Injectable({
  providedIn: 'root'
})
export class ProviderInvoiceOrdersService {
  constructor(
    private readonly apollo: Apollo
  ) {
  }


  delete(id: string): Observable<MutationResult<boolean>> {
    const mutation = gql`
      mutation deleteProviderInvoiceOrder($id: Guid!) {
        providerInvoiceOrders {
          delete(id: $id)
        }
      }
    `;

    const variables = {
      id
    };

    return this.apollo.mutate<boolean>({
      mutation,
      variables
    });
  }

  providerInvoiceOrder(id: string): Observable<ApolloQueryResult<ProviderInvoiceOrder>> {
    const query = gql`
      query providerInvoiceOrder($id: Guid!){
        providerInvoiceOrders  {
          providerInvoiceOrder(id: $id) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled

            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment

          }
        }
      }
    `;
    return this.apollo.query<{ providerInvoiceOrders: { providerInvoiceOrder: ProviderInvoiceOrder } }>({
      query,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders?.providerInvoiceOrder, ProviderInvoiceOrder),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }


  allProviderInvoiceOrders(parameters: PagedRequest<ProviderInvoiceOrdersFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ProviderInvoiceOrder>>> {
    const query = gql`
      query providerInvoiceOrders($offset: Int, $limit: Int, $filters: ProviderInvoiceOrdersFiltersRequest,  $sortProperty: String, $sortType: ESortType, $search: String) {
        providerInvoiceOrders {
          providerInvoiceOrders(offset: $offset, limit: $limit, filters: $filters,  sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              structure {
                name
                id
                address
                country
                city
                postalCode
                phoneNumber
                hasVat

              }
              updatedDate
              createdDate
              isOperationPointed
              totalAmountWithoutVat
              pennylaneFileUrl
              invoiceDate
              deadlineDate
              vatRate
              autoInvoiceEnabled
              providerInvoiceOrderSection {
                name
                rank
                providerInvoiceOrderDetail {
                  id
                  articleReference
                  articleLabel
                  additionalDescription
                  isOperationPointed
                  priceUnitWithoutVat
                  priceTotalWithoutVat
                  serviceId
                  quantity
                }
                customerSite {
                  id
                  name
                }
              }
              customer {
                id
                name
              }

              reference
              status
              comment
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{
      providerInvoiceOrders: { providerInvoiceOrders: PagedResponse<ProviderInvoiceOrder> }
    }>({
      query, variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        filters: parameters.filters,
        search: parameters.search
      },
      fetchPolicy: 'network-only'
    }).pipe(map(result => {
      const raw = result.data?.providerInvoiceOrders?.providerInvoiceOrders;
      const response: PagedResponse<ProviderInvoiceOrder> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, ProviderInvoiceOrderArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  updateInvoice(data: ProviderInvoiceOrderRequest): Observable<MutationResult<ProviderInvoiceOrder>> {
    const mutation = gql`
      mutation updateInvoice($id: Guid!, $data: ProviderInvoiceOrderRequest) {
        providerInvoiceOrders {

          update(id: $id, data: $data) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
          }
        }
      }
    `;

    return this.apollo.mutate<{ providerInvoiceOrders: { update: ProviderInvoiceOrder } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          reference: data.reference,
          comment: data.comment

        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders?.update, ProviderInvoiceOrder),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  deleteInvoiceDetail(id: string): Observable<MutationResult<ProviderInvoiceOrder>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        providerInvoiceOrders {
          removeDetail (id: $id) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
          }
        }
      }
    `;
    return this.apollo.mutate<{ providerInvoiceOrders: { removeDetail: ProviderInvoiceOrder } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders.removeDetail, ProviderInvoiceOrder),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  validateInvoice(id: string): Observable<MutationResult<ProviderInvoiceOrder>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        providerInvoiceOrders {
          validate (id: $id) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
          }
        }
      }
    `;
    return this.apollo.mutate<{ providerInvoiceOrders: { validate: ProviderInvoiceOrder } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders.validate, ProviderInvoiceOrder),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  prepareAllOrderInvoices(from: Date, to: Date): Observable<MutationResult<void>> {

    const timeZone = 'Europe/Paris'; // Définir votre timezone
    const fromTz = moment(from).tz(timeZone).format();
    const toTz = moment(to).tz(timeZone).format();

    const mutation = gql`
      mutation prepareAllOrderInvoices($from: DateTime!, $to: DateTime!)  {
        providerInvoiceOrders {
          prepareAllOrderInvoices(from: $from, to:$to)
        }
      }
    `;


    return this.apollo.mutate<void>({
      mutation,
      variables: {
        from: fromTz,
        to: toTz
      }
    });

  }


  validateInvoiceMultiple(...ids: string[]): Observable<MutationResult<ProviderInvoiceOrder[]>> {
    const query = `
      mutation validateInvoices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        providerInvoiceOrders {\n` + ids.map((_, index) => 'id' + index + ': validate (id: $id' + index + `) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
    }

       `).join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response: any, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.providerInvoiceOrders);
      data = data.filter(s => s !== 'ProviderInvoiceOrderMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  cancelMultiple(...ids: string[]): Observable<MutationResult<boolean[]>> {
    const query = `
      mutation deleteProviderInvoiceOrder(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        providerInvoiceOrders {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response: any, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.providerInvoiceOrders);
      data = data.filter(s => s !== 'ProviderInvoiceOrderMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  uploadInvoice(id: string, pdf: File, invoiceDate: Date, deadlineDate: Date, vatRate: string): Observable<MutationResult<ProviderInvoiceOrder>> {
    const mutation = gql`
      mutation uploadInvoice($id: Guid!, $pdf: Upload!, $invoiceDate: DateTime!, $deadlineDate: DateTime!, $vatRate: String!) {
        providerInvoiceOrders {
          uploadInvoice(id: $id, pdf: $pdf, invoiceDate: $invoiceDate, deadlineDate: $deadlineDate, vatRate: $vatRate) {id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
          }
        }
      }
    `;

    return this.apollo.mutate<{ providerInvoiceOrders: { uploadInvoice: ProviderInvoiceOrder } }>({
      mutation,
      variables: {
        id,
        pdf,
        invoiceDate,
        deadlineDate,
        vatRate

      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders.uploadInvoice, ProviderInvoiceOrder),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  markAsPaid(id: string): Observable<MutationResult<ProviderInvoiceOrder>> {

    const mutation = gql`
      mutation ($id: Guid!) {
        providerInvoiceOrders {
          markAsPaid (id: $id) {
            id
            structure {
              name
              id
              address
              country
              city
              postalCode
              phoneNumber
              hasVat

            }
            updatedDate
            createdDate
            isOperationPointed
            totalAmountWithoutVat
            pennylaneFileUrl
            invoiceDate
            deadlineDate
            vatRate
            autoInvoiceEnabled
            providerInvoiceOrderSection {
              name
              rank
              providerInvoiceOrderDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                isOperationPointed
                priceUnitWithoutVat
                priceTotalWithoutVat
                serviceId
                quantity
              }
              customerSite {
                id
                name
              }
            }
            customer {
              id
              name
            }

            reference
            status
            comment
          }
        }
      }
    `;
    return this.apollo.mutate<{ providerInvoiceOrders: { markAsPaid: ProviderInvoiceOrder } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.providerInvoiceOrders.markAsPaid, ProviderInvoiceOrder),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }
}
