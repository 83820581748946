export class PagedRequest<T = any> {
  /** Nombre de résultat maximum à retourner */
  limit?: number;

  /** Numéro de la page demandée */
  page?: number;

  /** Filtres spécifique aux objets demandés */
  filters?: Partial<T>;

  /** Trie les résultat par cette propriété */
  sortProperty?: string;

  /** Ordre du trie (ascendant ou descendant) */
  sortType?: 'ASC' | 'DESC';

  /** Recherche pour filtrer les résultats */
  search?: string;

  /** L'onglet dans lequel on est sur la page */
  tab?: number;

  constructor(index: {
    limit?: number;
    page?: number;
    filters?: Partial<T>;
    sortProperty?: string;
    sortType?: 'ASC' |'DESC';
    search?: string;
    tab?: number;
  }) {
    this.limit = index.limit;
    this.page = index.page;
    this.filters = index.filters;
    this.sortProperty = index.sortProperty;
    this.sortType = index.sortType;
    this.search = index.search;
    this.tab = index.tab;
  }

  get offset(): number {
    const page = this.page ?? 1;
    const limit = this.limit ?? 100;
    return (page - 1) * limit;
  }
}
