<form nz-form [formGroup]="filters" nzLayout="vertical">
  <div nz-row [nzGutter]="8">
    <div nz-col nzFlex="0 0 auto" *ngIf="checked.length > 0">
      <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu" [disabled]="actionLoading">
        <i *ngIf="actionLoading" nz-icon nzType="loading" class="loading-icon"></i>
        {{ checked.length }} {{ checked.length > 1 ? 'factures séléctionnées' : 'facture séléctionnée' }}
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #actionMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item nzDanger (click)="cancelChecked(cancelInvoiceTemplate, cancelInvoiceFooterTemplate)" *ngIf="checkedContainsDeletable">
            <i nz-icon nzType="delete"></i>
            Annuler
          </li>
          <ng-container *ngIf="checkedPossibleActions.length > 0">
            <li nz-menu-divider></li>
            <li nz-menu-item *ngFor="let action of checkedPossibleActions" (click)="doActionForChecked(action)">
              <ng-container *ngIf="(action | invoiceActionsIcon) as icon">
                <i nz-icon [nzType]="icon"></i>
              </ng-container>
              {{ action | invoiceActionsName}}
            </li>
          </ng-container>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="checked.splice(0, checked.length)">
            <i nz-icon nzType="minus-square"></i>
            Tout désélectionner
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
    <div nz-col nzFlex="1 1 auto">
      <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
        <ng-template #prefixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <input type="text" nz-input placeholder="Rechercher une facture" formControlName="search" #search />
      </nz-input-group>
    </div>
    <div nz-col nzFlex="0 0 auto">
      <button nz-button [nzType]="filtersAreVisible ? 'primary' : 'default'" (click)="toggleFilter()" nzSize="large">
        <i nz-icon nzType="filter"></i>
      </button>
    </div>
  </div>

  <div *ngIf="filtersAreVisible">
    <div nz-row nzGutter="8" class="mt-8">
      <div nz-col [nzSm]="24" [nzMd]="11" [nzXl]="3">
        <nz-form-item>
          <nz-form-label nzFor="status">Statut</nz-form-label>
          <nz-form-control>
            <nz-select id="status" nzSize="large" nzMode="multiple" nzPlaceHolder="Tous les statuts" [nzShowArrow]="true" [nzShowSearch]="true" [nzAllowClear]="true" formControlName="status">
              <nz-option *ngFor="let status of filterStatus" [nzLabel]="status | invoiceStatusName | nullCoal" [nzValue]="status"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5" *ngIf="userCanSeeCustomerFilter">
        <laveo-select-actor
          [control]="filters.get('customers')"
          [typeEntity]="filterClientTypeEntity"
          [typeSelection]="'multiple'"
          [typeSize]="'large'"
          [placeholder]="filterClientPlaceholder">
        </laveo-select-actor>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
        <nz-form-item>
          <nz-form-label nzFor="dates">Dates</nz-form-label>
          <nz-form-control>
            <nz-range-picker id="dates" class="w-100-p" nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dates" [nzRanges]="dateRanges"></nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
</form>


<ng-template #cancelInvoiceTemplate>
  <p *ngIf="this.cancellable.length === 1">Êtes-vous sûr de vouloir annuler la facture ?</p>
  <p *ngIf="this.cancellable.length > 1">Êtes-vous sûr de vouloir annuler les <b>{{cancellable.length}} factures sélectionnées</b> ?</p>
  <ng-container *ngIf="this.checked.length !== this.cancellable.length">
    <p *ngIf="this.checked.length - this.cancellable.length === 1">({{ this.checked.length - this.cancellable.length }} autre facture ne peut plus plus être annulée à cause de son statut)</p>
    <p *ngIf="this.checked.length - this.cancellable.length > 1">({{ this.checked.length - this.cancellable.length }} autres factures ne peuvent plus plus être annulées à cause de leurs statut)</p>
  </ng-container>
  <p>
    <br />
    Attention : cette opération est irréversible. Si vous supprimez, vous aurez besoin de recréer chaque facture associée aux prestations présentes.<br /><br/>
    Etes-vous sûr(e) de vouloir continuer ?
  </p>
</ng-template>

<ng-template #cancelInvoiceFooterTemplate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Fermer</button>
  <button nz-button nzType="primary" nzDanger (click)="closeCancelModal(ref)" [nzLoading]="actionLoading">Annuler</button>
</ng-template>
