import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InvoiceRequest, StatusInvoice} from "../../../../../shared/models/entities/invoice-request";
import {InvoiceRequestDetail} from "../../../../../shared/models/entities/invoice-request-detail";
import {UserRole, UserRoleType} from "../../../../../shared/models/entities/user-role";
import {UserRight} from "../../../../../shared/models/entities/user-right";
import {UserService} from "../../../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {NzModalService} from "ng-zorro-antd/modal";
import {InvoiceRequestsService} from "../../../../../shared/services/api/invoice-requests.service";
import {ApiErrorMessageUtil} from "../../../../../shared/utils/api-error-message.util";
import {NzMessageService} from "ng-zorro-antd/message";
import {Utils} from "../../../../../shared/utils/utils";

@Component({
  selector: 'laveo-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit, OnDestroy {
  @Input() invoice?: InvoiceRequest;

  userCanEdit = false;
  userCanDelete = false;
  currentRole?: UserRole;
  actionLoading = false;
  isOrderNumberEditing: boolean;
  orderNumberValue: string | undefined;
  isUpdating = false;
  isCustomer = false;
  isCustomerSite = false;
  protected readonly StatusInvoice = StatusInvoice;
  private subscriptions: Subscription[] = [];
  protected isAdmin: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService,
    private readonly invoiceService: InvoiceRequestsService,
  ) {}


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.orderNumberValue = this.invoice?.orderReference;
  }


  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /*
   * Returns true if the invoice can be updated.
   * This means the invoice status is Created of ValidationRequired
   */
  canDoActionOnInvoice(invoice: InvoiceRequest | undefined) {
    return invoice?.status == StatusInvoice.created || invoice?.status == StatusInvoice.validationRequired || invoice?.status == StatusInvoice.customerValidationRequired;
  }

  /**
   * Deletes a detail.
   * Open a warning because the invoice line need to be recreated manually.
   */
  removeInvoiceDetail(detail: InvoiceRequestDetail) {
    // console.log("Delete invoice line :", detail);

    this.modal.confirm({
      nzTitle: 'Supprimer la ligne ' + detail.articleReference + ' ?',
      nzContent: `Êtes-vous sûr de vouloir supprimer la ligne  <b>${detail.articleReference}</b> ?<br /> ` +
        "Attention : cette opération est irréversible. Si vous supprimez la ligne, vous aurez besoin de créer une facture " +
        " manuellement sur Pennylane afin de refacturer cette ligne.<br /><br/> " +
        "Etes-vous sûr(e) de vouloir continuer ?",
      nzOkText: 'Supprimer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.invoiceService.deleteInvoiceDetail(detail.id).subscribe({
          next: response => {
            //console.log("Response", response);
            // If response is ok, remove line
            if (response.data) {
              this.invoice = response.data;
            }

            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
          }
        });


      }
    });
  }

  validate() {

    if (!this.invoice) {
      // Error

      this.modal.create({
        nzTitle: 'Erreur',
        nzContent: "Il n'y a pas de facture chargée. Vous ne pouvez pas valider la facture",
        nzFooter: [
          {
            label: 'OK',
            onClick: () => this.modal.closeAll()
          }
        ]
      });

    }
    // Todo check if invoice empty
    this.modal.confirm({
      nzTitle: 'Valider la facture ' + this.invoice?.reference + ' ?',
      nzContent: `Êtes-vous sûr de vouloir valider la facture <b>${this.invoice?.reference}</b> ?<br /> ` +
        "Attention : une fois la facture validée, elle va être envoyée à Pennylane et ne pourra plus être modifiée " +
        " ni annulée.<br /><br/> " +
        "Etes-vous vraiment sûr(e) de vouloir continuer ?",
      nzOkText: 'Valider la facture',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.invoiceService.validateInvoice(this.invoice!.id).subscribe({
          next: response => {
            //console.log("Response", response);
            // If response is ok, remove line
            if (response.data) {
              this.invoice = response.data;
            }
            // Check the errors

            this.scrollToTop();
            this.actionLoading = false;
          },
          error: error => {
            console.error("Error:", error);
            // Check if the error is a GraphQL error
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            this.scrollToTop();
            this.actionLoading = false;
          }
        });


      }
    });
  }

  /**
   * Update the order number
   */
  updateOrderNumber(showModal:boolean) {
    //console.log("Update order number");

    if (Utils.isBlank(this.orderNumberValue) || !this.orderNumberValue) {
      this.message.error("Vous devez saisir un numéro de commande");
      return;
    }
    if (this.invoice == null) {
      return;
    }
    this.isUpdating = true;
    const invoiceId = this.invoice.id;
    const orderNumber = this.orderNumberValue;

    if (showModal) {

      this.modal.confirm({
        nzTitle: 'Valider le numéro de commande "' + this.orderNumberValue + '" ?',
        nzContent: `Êtes-vous sûr de vouloir valider ce numéro de commande ?  <b>${this.orderNumberValue}</b> ?<br /> ` +
          "Attention : une fois validé, une facture va être générée et cette opération est irréversible. <br /><br/> " +
          "Etes-vous vraiment sûr(e) de vouloir continuer ?",
        nzOkText: 'Valider le numéro de commande',
        nzOkType: 'primary',
        nzCancelText: 'Annuler',
        nzOnOk: () => {
          this.callUpdateOrderReference(invoiceId, orderNumber);

        }
      });
    }
    else {
      this.callUpdateOrderReference(invoiceId, orderNumber);

    }

  }

  getValidationText() {

    if (this.invoice?.status == StatusInvoice.validated) {
      return "Facturer";
    }
    return "Valider";

  }

  // Save the order number by API call
  saveOrderNumberForCustomer(event: Event) {

    event.stopPropagation(); // Prevent triggering clickOutside
    // Call API
    this.updateOrderNumber(true);
  }

  // Save the order number by API call
  saveOrderNumber(event: Event) {

    event.stopPropagation(); // Prevent triggering clickOutside
    // Call API
    this.updateOrderNumber(false);
  }

  cancelEditing() {
    //console.log("cancel editing");
    this.isOrderNumberEditing = false;
  }

  editOrderNumber() {

    //console.log("edit");
    this.orderNumberValue = this.invoice?.orderReference;
    this.isOrderNumberEditing = true;
    setTimeout(() => {
      // Allow the click event to complete before starting to listen for outside clicks
    }, 0);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.isCustomer = role.type === UserRoleType.customer;
      this.isCustomerSite = role.type === (UserRoleType.customerSite ||UserRoleType.customerSiteRead);

      this.userCanEdit = this.isAdmin || role.rights.invoiceRequests.includes(UserRight.update);
      this.userCanDelete = this.isAdmin || role.rights.invoiceRequests.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Détail de la facture ' + this.invoice?.reference);
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  isOrderNumberEditable() {
    // Until the invoice is invoiced on Pennylane, we can update it
    if (this.isAdmin && (this.invoice?.status ==  StatusInvoice.created  || this.invoice?.status == StatusInvoice.validated  || this.invoice?.status == StatusInvoice.validationRequired || this.invoice?.status == StatusInvoice.customerValidationRequired)) {
      return true;
    }
    else if ((this.isCustomer || this.isCustomerSite) && this.invoice?.status == StatusInvoice.customerValidationRequired) {
      return true;
    }
    return false;

  }

   callUpdateOrderReference(invoiceId: string, orderNumber: string) {
    this.actionLoading = true;

    this.invoiceService.updateOrderReference(invoiceId, orderNumber).subscribe({
      next: response => {
        // If response is ok, remove line
        if (response.data) {
          this.invoice = response.data;
        }

        this.isUpdating = false;
        this.isOrderNumberEditing = false;
        this.actionLoading = false;
        this.isUpdating = false;
      },
      error: error => {
        //console.log("Erreur :", error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        this.isUpdating = false;
        this.actionLoading = false;
        this.isUpdating = false;
      }
    });
  }

  }
