
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql, MutationResult} from 'apollo-angular';
import {BatchProcessPricing} from "../../models/entities/batch-process-pricing";
import {map} from "rxjs/operators";
import {TypeSerializerUtils} from "../../utils/type-serializer.util";

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  constructor(
    private readonly apollo: Apollo
  ) {}


  importPricings(file: File): Observable<MutationResult<BatchProcessPricing>> {

    const mutation = gql`
      mutation importPricingsFromExcel( $file: Upload!) {
        pricings {

          importFromExcel(file: $file) {
            processOk {
              id
            }
            processFailed {
              message
              object {
                id
                customerSiteId
              }
            }
          }
        }
      }
    `;


    return this.apollo.mutate <{ pricings: { importFromExcel: BatchProcessPricing } }>({
      mutation,
      "variables": {
        file
      },
      "context": {
        "useMultipart": true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.pricings?.importFromExcel, BatchProcessPricing),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));


    return new Observable();
  }
}


