import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRightCategory } from '../models/entities/user-right';
import { UserService } from '../services/api/user.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard  {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.userService.currentRole
    .pipe(map(role => {
      const rights = Object.keys(role.rights).filter(right => right !== '__typename' && role.rights[right].length > 0);

      for (const right of rights) {
        //console.log("Right : " + right + "- next : "+ next.routeConfig?.path);
        if (next.routeConfig?.path?.startsWith(right)) {
          return true;
        }

        if (right === UserRightCategory.customerSites && next.routeConfig?.path?.startsWith('customer-sites')) {
          return true;
        }
      }

      void this.router.navigate(['/', 'admin']);
      return false;
    }));
  }
}
