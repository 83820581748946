import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, take} from 'rxjs/operators';
import {Customer} from 'src/app/shared/models/entities/customer';
import {CustomerSite} from 'src/app/shared/models/entities/customer-site';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
  addMonths,
  endOfMonth,
  endOfToday,
  endOfWeek,
  isEqual,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear
} from 'date-fns';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {UserService} from 'src/app/shared/services/api/user.service';
import {UserRoleType} from 'src/app/shared/models/entities/user-role';
import {ApolloError} from '@apollo/client/core';
import {InvoiceRequest, StatusInvoice} from "../../../../shared/models/entities/invoice-request";
import {PagedRequest} from "../../../../shared/models/api/shared/paged/paged-request";
import {InvoiceRequestsService} from "../../../../shared/services/api/invoice-requests.service";
import {ActorsService} from "../../../../shared/services/api/actors.service";
import {ApiErrorMessageUtil} from "../../../../shared/utils/api-error-message.util";
import {InvoiceRequestActions} from "../../../../shared/models/entities/invoice-actions";
import {
  InvoiceRequestsFiltersRequest
} from "../../../../shared/models/api/services/invoice-requests-filters-requests.interface";

@Component({
  selector: 'laveo-filter-invoices',
  templateUrl: './filter-invoices.component.html',
  styleUrls: ['./filter-invoices.component.scss']
})
export class FilterInvoicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() originalList: InvoiceRequest[] = [];
  @Input() checked: InvoiceRequest[] = [];
  @Input() queryStringFilters = new BehaviorSubject<PagedRequest<InvoiceRequestsFiltersRequest> | null>(null);
  @Input() autofocus = false;
  @Output() filtersChanged = new EventEmitter<PagedRequest<InvoiceRequestsFiltersRequest>>();
  @Output() checkedChanged = new EventEmitter();

  @ViewChild('search') private searchInput: ElementRef<HTMLInputElement>;

  actionLoading = false;
  filtersAreVisible = false;
  filters: UntypedFormGroup;
  filterStatus: StatusInvoice[] = Object.values(StatusInvoice);
  filterClientTypeEntity = [Customer, CustomerSite];
  userCanSeeCustomerFilter = true;
  filterClientPlaceholder = 'Clients et sites client';
  dateRanges: Record<string, Date[]> = {};

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly invoiceService: InvoiceRequestsService,
    private readonly actorsService: ActorsService,
    private readonly userService: UserService,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  get checkedContainsDeletable(): boolean {
    const cancelable = this.checked.find(invoice => invoice.status === StatusInvoice.created || invoice.status === StatusInvoice.validationRequired);
    return cancelable !== undefined;
  }

  get checkedPossibleActions(): InvoiceRequestActions[] {

    return [
      InvoiceRequestActions.validate]

  }

  get cancellable(): InvoiceRequest[] {
    return this.checked.filter(invoice => [
      StatusInvoice.created,
      StatusInvoice.validationRequired
    ].includes(invoice.status));
  }

  ngOnInit(): void {
    this.setFiltersForm();
    this.loadRole();

    this.subscriptions.push(
      this.queryStringFilters.subscribe(f => this.setFilterFromQueryString(f))
    );
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  toggleFilter(): void {
    this.filtersAreVisible = !this.filtersAreVisible;
  }

  cancelChecked(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Annulation multiple',
      nzContent: content,
      nzFooter: footer
    });
  }


  doActionForChecked(action: InvoiceRequestActions): void {
    switch (action) {
      case InvoiceRequestActions.validate: {
        this.validate();
        break;
      }
    }
  }

  loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe((role) => {
      if (role.type === UserRoleType.customer) {
        this.filterClientPlaceholder = 'Sites client';
        this.filterClientTypeEntity = [CustomerSite];
      }

      if (
        role.type === UserRoleType.customerSite ||
        role.type === UserRoleType.customerSiteRead
      ) {
        this.userCanSeeCustomerFilter = false;
      }


    });
    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setFiltersForm(): void {
    this.filters = this.formBuilder.group({
      invoiced: this.formBuilder.control(null),
      search: this.formBuilder.control(null),
      status: this.formBuilder.control([]),
      customers: this.formBuilder.control([]),
      dates: this.formBuilder.control([startOfYear(startOfToday()), startOfToday()])
    });


    this.setFilterFromQueryString();

    this.dateRanges = {
      'Aujourd\'hui': [startOfToday(), endOfToday()],
      'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      'Ce mois': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };

    const filterSubscription = this.filters.valueChanges.pipe(debounceTime(500)).subscribe((filtersForm: FiltersFormValues) => {
      let minDate = filtersForm.dates.length > 0 ? filtersForm.dates[0] : undefined;
      let maxDate = filtersForm.dates.length > 0 ? filtersForm.dates[1] : undefined;
      if (minDate && isEqual(minDate, startOfToday()) && maxDate && isEqual(maxDate, addMonths(startOfToday(), 6))) {
        minDate = undefined;
        maxDate = undefined;
      }
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(23, 59, 29, 999);

      const filters: InvoiceRequestsFiltersRequest = {
        minDate,
        maxDate,
        status: filtersForm.status.length > 0 ? filtersForm.status : [],
        customers: filtersForm.customers.filter(c => c.__typename === 'Customer').map(c => c.id),
        customerSites: filtersForm.customers.filter(c => c.__typename === 'CustomerSite').map(c => c.id),
      };


      // Remove empty filters
      Object.keys(filters).forEach(key => {
        if (filters[key] === undefined || (Array.isArray(filters[key]) && filters[key].length === 0)) {
          delete filters[key];
        }
      });

      this.filtersChanged.emit(new PagedRequest<InvoiceRequestsFiltersRequest>({
        page: this.queryStringFilters.value?.page,
        filters,
        search: filtersForm.search || undefined
      }));

      if (this.checked.length > 0) {
        this.checked.splice(0, this.checked.length);
        this.checkedChanged.emit();
      }
    });

    this.subscriptions.push(filterSubscription);
  }

  private setFilterFromQueryString(queryStringFilters: PagedRequest<InvoiceRequestsFiltersRequest> | null = null): void {
    if (!queryStringFilters) {
      queryStringFilters = this.queryStringFilters.value;
    }

    if (!queryStringFilters) {
      return;
    }

    let dates: Date[] = this.filters.get('dates')?.value ?? [];
    if (queryStringFilters?.filters?.minDate && queryStringFilters?.filters?.maxDate) {
      dates = [queryStringFilters?.filters?.minDate, queryStringFilters?.filters?.maxDate];
    }

    const ids = [...(queryStringFilters?.filters?.customers ?? []), ...(queryStringFilters?.filters?.customerSites ?? [])];
    const customers: (Customer | CustomerSite)[] = [];

    const afterRequest = () => {
      this.filters.patchValue({
        search: queryStringFilters?.search,
        status: queryStringFilters?.filters?.status ?? [],
        customers,
        dates
      });

      let hasFilters = [
        this.filters.get('status')?.value,
        this.filters.get('customers')?.value,
      ].some(filter => filter?.length > 0);

      const filterDates = this.filters.get('dates')?.value as Date[];
      const hasFilterDates = filterDates.length > 0 && (!isEqual(filterDates[0], startOfToday()) || !isEqual(filterDates[1], addMonths(startOfToday(), 6)));
      hasFilters = hasFilters || hasFilterDates;

      this.filtersAreVisible = this.filtersAreVisible || hasFilters;
    };

    if (ids.length > 0) {
      this.actorsService.actorsByIds(ids).pipe(take(1)).subscribe({
        next: response => {
          response.data.forEach(actor => {
            if (actor instanceof Customer || actor instanceof CustomerSite) {
              customers.push(actor);
            }

          });
          afterRequest();
        },
        error: (error: ApolloError) => {
          afterRequest();
          this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        }});
    } else {
      afterRequest();
    }
  }

  // Checked actions
  private validate(): void {
    const confirmable = this.checked.filter(invoice => invoice.status === StatusInvoice.created || invoice.status === StatusInvoice.validationRequired);
    let nzContent = `Êtes-vous sûr de vouloir valider <b>${confirmable.length > 1 ? `les ${confirmable.length} factures sélectionnées` : 'la facture sélectionnée'}</b> ?`;
    if (confirmable.length !== this.checked.length) {
      const notConfirmableLength = this.checked.length - confirmable.length;
      nzContent += notConfirmableLength === 1 ? '<br><br> (' + notConfirmableLength + ' autre facture ne peut pas être validée à cause de son statut)' : '<br><br> (' + notConfirmableLength + ' autres factures ne peuvent pas être validées à cause de leurs statuts)';
      if ((this.checked.length - notConfirmableLength) == 0) {

        this.message.error('Aucune facture ne peut être validée à cause de leur statut.');
        return;
      }
    }

    nzContent += `<br />Attention : une fois la facture validée, elle va être envoyée à Pennylane et ne pourra plus être modifiée  ni annulée.<br /><br/>
        Êtes-vous vraiment sûr(e) de vouloir continuer ? `;

    this.modal.confirm({
      nzTitle: 'Validation multiple',
      nzContent,
      nzOkText: 'Valider',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Validation en cours...');
        this.invoiceService.validateInvoiceMultiple(...confirmable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de la validation. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 facture validée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} factures validées avec succès.`);
            }

            // Override existing values
            if (response.data) {
              this.updateInvoiceRequestList(this.originalList, response.data);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  closeCancelModal(modal: NzModalRef): void {

    this.actionLoading = true;
    this.invoiceService.cancelMultiple(...this.cancellable.map(s => s.id)).subscribe({
      next: response => {
        if ((response.data?.length ?? 0) === 0) {
          this.message.error('Une erreur est survenue lors de l\'annulation. Réessayer plus tard.');
        } else if ((response.data?.length ?? 0) === 1) {
          this.message.info(`1 facture annulée avec succès.`);
        } else {
          this.message.info(`${response.data?.length} factures annulées avec succès.`);
        }
        this.actionLoading = false;
        this.checked.splice(0, this.checked.length);
        if (this.queryStringFilters?.value) {
          this.queryStringFilters.value.page = 1;
        }
        this.checkedChanged.emit();
        modal.destroy();
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }


  private updateInvoiceRequestList(originalList: InvoiceRequest[], updatedList: InvoiceRequest[]): InvoiceRequest[] {
    updatedList.forEach(updatedItem => {
      const index = originalList.findIndex(item => item.id === updatedItem.id);
      if (index >= 0) {
        originalList[index] = updatedItem;
      }
    });
    return originalList;
  }




}

interface FiltersFormValues {
  search: string;
  status: StatusInvoice[];
  customers: (Customer | CustomerSite)[];
  dates: Date[];
}
