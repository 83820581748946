<div nz-row [nzGutter]="[48, 24]">
 <div nz-col nzSpan="24">
   <a nz-button nzType="primary" class="mr-8" target="_blank" [href]="bilanRseUrl + customerSite?.id + '.pdf'">Voir le bilan RSE en PDF</a>
 </div>

  <div nz-col nzSpan="24" *ngIf="customerSite?.logo?.urlWithDomain">
    <img [src]="customerSite?.logo?.urlWithDomain" alt="Logo {{ customerSite?.name }}" class="logo">
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Actif</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="customerSite?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ customerSite?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Nom</span>
          <span class="detail-content" nz-typography>{{ customerSite?.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Adresse</span>
      <span class="detail-content" nz-typography>{{ customerSite?.fullAddress | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Structure</span>
          <span class="detail-content" nz-typography>
            <button
              nz-button
              nzSize="large"
              [routerLink]="['/', 'admin', 'structures', customerSite?.structure?.id]"
              [disabled]="!customerSite?.structure?.id">
              {{ customerSite?.structure?.name }}<i nz-icon nzType="right-square"></i>
            </button>
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Client</span>
          <span class="detail-content" nz-typography>
            <button
              nz-button
              nzSize="large"
              [routerLink]="['/', 'admin', 'customers', customerSite?.customer?.id]"
              [disabled]="!customerSite?.customer?.id">
              {{ customerSite?.customer?.name }}<i nz-icon nzType="right-square"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>


  <div nz-col nzSpan="24">
    <laveo-divider title="Prestations" icon="menu"></laveo-divider>

    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Type contrat</span>
          <span class="detail-content" nz-typography>{{ customerSite?.contractType | customerSiteContractTypeName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Type workflow</span>
          <span class="detail-content" nz-typography>{{ customerSite?.workflowType | customerSiteWorkflowTypeName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Site couvert</span>
          <span class="detail-content" nz-typography>{{ customerSite?.shelteredSite | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Accès à l'électricité</span>
          <span class="detail-content" nz-typography>{{ customerSite?.electricityAccess | booleanPipe }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Type de prestation</span>
          <span class="detail-content" nz-typography>{{ customerSite?.type | serviceTypeName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Finition</span>
          <span class="detail-content" nz-typography>{{ customerSite?.finishing | serviceFinishingName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Détachage sellerie</span>
          <span class="detail-content" nz-typography>{{ customerSite?.upholsteryStainRemoval | serviceUpholsteryStainRemovalName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Détachage moquette</span>
          <span class="detail-content" nz-typography>{{ customerSite?.carpetStainRemoval | serviceCarpetStainRemovalName | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">1ère intervention</span>
          <span class="detail-content" nz-typography>{{ customerSite?.firstIntervention | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Véhicule très sale</span>
          <span class="detail-content" nz-typography>{{ customerSite?.vehicleVeryDirty | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Convoyage</span>
          <span class="detail-content" nz-typography>{{ customerSite?.conveying | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Stickage</span>
          <span class="detail-content" nz-typography>{{ customerSite?.sticking | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Déstickage</span>
          <span class="detail-content" nz-typography>{{ customerSite?.desticking | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Désinfection habitacle</span>
          <span class="detail-content" nz-typography>{{ customerSite?.interiorDisinfection | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Désinfection zone de contact</span>
          <span class="detail-content" nz-typography>{{ customerSite?.contactZoneDisinfection | booleanPipe }}</span>
        </div>
      </div>
    </div>
  </div>


  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Fréquence affecté</span>
          <span class="detail-content" nz-typography>Tous les {{ customerSite?.frequencyServiceAffectedVehicle | nullCoal }} jours</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Fréquence mutualisé</span>
          <span class="detail-content" nz-typography>Tous les {{ customerSite?.frequencyServiceMutualizedVehicle | nullCoal }} jours</span>
        </div>
      </div>
    </div>
  </div>


  <div nz-col nzSpan="24" *ngIf="isAdmin">
    <laveo-divider title="Facturation" icon="euro"></laveo-divider>

    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Facturation activée</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingEnabled | booleanPipe }}</span>
        </div>
      </div>
      <div nz-col  *ngIf="customerSite?.billingEnabled">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Préfixe des articles Pennylane</span>
          <span class="detail-content" nz-typography>{{ customerSite?.invoiceItemPrefix  }}</span>
        </div>
      </div>
      <div nz-col *ngIf="customerSite?.billingEnabled">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Validation manuelle nécessaire</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingNeedsManualValidation  }}</span>
        </div>
      </div>
      <div nz-col *ngIf="customerSite?.billingEnabled">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Pennylane mode brouillon</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingPennylaneDraftMode  }}</span>
        </div>
      </div>


    </div>
    <div nz-row [nzGutter]="[48, 0]" *ngIf="customerSite?.billingEnabled">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Facturation par véhicule</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingByVehicle | booleanPipe }}</span>
        </div>
      </div>
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Facturation groupée client</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingGroupByCustomer | booleanPipe }}</span>
        </div>
      </div>
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Fréquence de facturation</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingFrequency | customerSiteFrequency }}</span>
        </div>
      </div>

      <div nz-col nzSpan="10">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Description article additionnelle</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingAdditionalParamsForProductLabel | nullCoal }}</span>
        </div>
      </div>
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Numéro de commande nécessaire avant facturation ?</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingIsAgreementRequiredBeforeInvoicing | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col nzSpan="8">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">E-mail pour l'envoi de la demande</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingAgreementEmail | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Numéro d'ordre nécessaire ?</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingIsOrderNumberRequired | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Numéro d'ordre annuel ?</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingYearlyOrderNumber| nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Dépôt sur un site externe ?</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingToUploadOnTool | booleanPipe }}</span>
        </div>
      </div>
      <div nz-col *ngIf="customerSite?.billingToUploadOnTool">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">URL du site de dépôt ?</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingUploadToolUrl| nullCoal }}</span>
        </div>
      </div>


    <div nz-col nzSpan="12">
      <div class="detail">
        <span class="detail-title" nz-typography nzType="secondary">Adresse de facturation</span>
        <span class="detail-content" nz-typography [innerHTML]="getBillingAddress() | nullCoal">
        </span>
      </div>
    </div>

      <div nz-col nzSpan="12">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">E-mail de facturation</span>
          <span class="detail-content" nz-typography>{{ customerSite?.billingEmail | booleanPipe }}</span>
        </div>
      </div>

      <div nz-col nzSpan="12">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">SIREN</span>
          <span class="detail-content" nz-typography>{{ customerSite?.registrationId | nullCoal }}</span>
        </div>
      </div>
      <div nz-col nzSpan="12">
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">N&deg; TVA Intracommunautaire</span>
          <span class="detail-content" nz-typography>{{ customerSite?.vatNumber | nullCoal }}</span>
        </div>
      </div>

    </div>

    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Responsable facturation</span>
      <span class="detail-content" nz-typography>{{ customerSite?.billingPerson | customerSiteBillingPersonName | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Texte facturation</span>
      <span class="detail-content" nz-typography>{{ customerSite?.billingText | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">

    <laveo-divider title="Points de contrôle" icon="radar-chart"></laveo-divider>
    <div class="detail w-100-p">
      <div class="detail-content" nz-typography>
        <ul *ngFor="let checkpointCategory of vehicleCheckpointGroupedByCategory">
          <li>
            <b>{{ checkpointCategory.key }}</b>
            <ul *ngFor="let checkpoint of checkpointCategory.value">
              <li>{{ checkpoint.name }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <laveo-list-contacts [contacts]="customerSite && customerSite.contacts ? customerSite.contacts : []" [showNotificationsCheckbox]="true"></laveo-list-contacts>
  </div>
</div>
